export const TRANSLATIONS_EN = {
  admin_panel_database_status: 'Database status',
  admin_panel_metrics: 'Metrics (since the last server restart)',
  admin_panel_metrics_average: 'Average analysis',
  admin_panel_metrics_count: 'Total analyses',
  admin_panel_metrics_internal_server_error: 'Internal server errors encountered',
  admin_panel_metrics_max: 'Longest analysis',
  admin_panel_metrics_no_data: 'No data',
  admin_panel_metrics_seconds: 'seconds',
  admin_panel_texts_to_review: 'Texts awaiting review',
  analyse_button: 'Analyse',
  applied_filters: 'Applied filters',
  back: 'PREVIOUS',
  beginning: 'beginning',
  collocates_filename: 'collocates',
  collocates_graph: 'Graph',
  collocates_graph_filename: 'graph',
  collocates_graph_modal_tooltip: 'Show neighbouring words on a graph',
  collocates_graph_slider_title: 'Specify whether you want to see fewer or more neighbouring words on the graph',
  collocates_graph_tooltip: 'The graph shows collocates of the selected word, illustrating their importance and position in text. Neighbouring words are located to the left, right, or center of the search word, depending on whether they tend to precede it, follow it or appear on both sides of it. Neighbouring words with a higher collocation score are marked by a larger dot. By default, up to 30 most important collocates are shown but their number can be changed if wanted.',
  common_about: 'About',
  common_admin_panel: 'Admin panel',
  common_analysis_options: 'Analysis options',
  common_analyzing_text: 'Analysing text...',
  common_and: 'and',
  common_articles: 'Articles',
  common_audiovisual_media: 'Audiovisual media',
  common_author_data: 'Author data',
  common_by_base_form: 'by base form',
  common_by_word_form: 'by word form',
  common_case_sensitive: 'case-sensitive',
  common_clusters: 'Cluster Catcher',
  common_conferences_and_workshops: 'Conferences and workshops',
  common_corrector: 'Writing Evaluator',
  common_definition: 'Definition (Sõnaveeb)',
  common_dictionaries: 'Dictionaries',
  common_different_word_count: 'number of different words',
  common_donate_text: 'Donate your text',
  common_download: 'Download',
  common_enter_search_word: 'Enter search word',
  common_estonian_language_corpora: 'Estonian language corpora',
  common_estonian_texts: 'Estonian texts',
  common_excel_sheet_name: 'Table',
  common_for_teachers: 'For teachers',
  common_form: 'Form',
  common_graduation_papers: 'Graduation papers',
  common_grants: 'Grants',
  common_header_frequency: 'Frequency',
  common_header_number: 'No',
  common_header_percentage: 'Percentage',
  common_hello: 'Hello',
  common_here: 'here',
  common_indexes: 'Indexes',
  common_introduction: 'Introduction',
  common_learning_environments_and_courses: 'Learning environments and courses',
  common_learning_resources: 'Learning resources',
  common_lemma: 'Base form',
  common_lemmas: 'Base forms',
  common_license_cc_by_4_0: 'CC BY 4.0',
  common_links: 'Link collections',
  common_login_for_admins: 'Login for administrators',
  common_long_word_count: 'number of long words',
  common_neighbouring_words: 'Neighbouring Words',
  common_other: 'other',
  common_people: 'Employees',
  common_polysyllabic_words: 'number of polysyllabic words',
  common_publications: 'Publications',
  common_publish_your_text: 'Text publishing',
  common_query: 'Text query',
  common_search: 'Search',
  common_sentence_count: 'number of sentences',
  common_sentences: 'Sentence count',
  common_statistics: 'Statistics',
  common_syllable_count: 'number of syllables',
  common_syllables: 'Syllables',
  common_text_data: 'Text data',
  common_text_data_field_of_research: 'Field of research',
  common_text_data_field_of_research_biosciences_and_environment: 'biosciences and environment',
  common_text_data_field_of_research_culture_and_society: 'culture and society',
  common_text_data_field_of_research_health: 'health',
  common_text_data_field_of_research_natural_sciences_and_engineering: 'natural sciences and engineering',
  common_text_data_type_L2_olympiade_creative_writing: 'L2 olympiade creative writing',
  common_text_data_type_L2_proficiency_examination: 'L2 proficiency exam',
  common_text_data_type_creative_writing: 'Creative writing',
  common_text_data_type_examination: 'Examination',
  common_text_data_type_exercise: 'Exercise',
  common_text_data_type_opinion_piece: 'Opinion piece',
  common_text_data_type_test: 'Test',
  common_text_data_type_translation: 'Translation',
  common_tool: 'tool',
  common_tools: 'Texts & tools',
  common_translation: 'Translation (Google Translate)',
  common_translation_tools: 'Translation tools',
  common_us: 'About us',
  common_view: 'View',
  common_word_analyser: 'Word Analyser',
  common_word_analysis: 'Word Analysis',
  common_word_count: 'number of words',
  common_word_in_context: 'Word in Context',
  common_wordlist: 'Wordlist',
  common_words: 'Word count',
  common_words_in_text: 'Words in text',
  common_wordtype: 'Part of speech',
  complexity_level_average: 'average',
  complexity_level_difficult: 'difficult',
  complexity_level_easy: 'easy',
  complexity_level_very_difficult: 'very difficult',
  complexity_level_very_easy: 'very easy',
  concordances: 'Usage Contexts',
  concordances_before_and_after_selected_word: 'before and after selected word',
  concordances_case_sensitive_hover: 'Search words beginning with uppercase or lowercase letters are not recognized by default, e.g. searching for "eesti" allows you to find the contexts of both "eesti" and "Eesti". Tick the box if you only want the results beginning with uppercase or lowercase letters.',
  concordances_following_context: 'Following context',
  concordances_keyword_lemmatization_warning: 'The keyword "{{initialKeywordResult}}" had no matches. Automatic lemmatization was used and contexts for the word "{{lemmatizedKeywordResult}}" were found.',
  concordances_preceding_context: 'Preceding context',
  concordances_search_word: 'Search word',
  concordances_sentences: 'sentences',
  concordances_view: 'View',
  concordances_words: 'words',
  cookie_acknowledgement_snackbar_button: 'I agree',
  cookie_acknowledgement_snackbar_content_1: 'ELLE website uses cookies that are necessary for the page to function.',
  cookie_acknowledgement_snackbar_content_2: 'By using the website, you agree to the cookies being stored on your device.',
  corpus_donation_text: 'Help us research Estonian language and donate your writing to our text collection. Texts from both language learners and native speakers are appreciated.',
  corpus_text_count: '12 000+',
  corpus_texts: 'texts to analyse',
  corpus_word_count: '3 000 000+',
  corpus_words: 'words in our text collection',
  correction_vocabulary_data: 'data',
  corrector_complexity: 'Complexity',
  corrector_complexity_gray_box: 'The complexity of the text is an indicator of the author\'s language skills, as well as how easy it is to read. Determine where the text falls on the scale from easy to difficult and, if necessary, improve its readability.',
  corrector_complexity_infobox_flesch_kincaid_bold: 'The Flesch-Kincaid grade level',
  corrector_complexity_infobox_flesch_kincaid_value: 'takes into account the average sentence length in words and the average word length in syllables:',
  corrector_complexity_infobox_intro: 'The corrector assesses the complexity of a text based on the following metrics.',
  corrector_complexity_infobox_lix_bold: 'The LIX index',
  corrector_complexity_infobox_lix_outro: 'The verbal reasoning is determined by the LIX index, which has been studied by Helin Puksand (2004) in relation to the perceived complexity of the text. Read about LIX and nominality',
  corrector_complexity_infobox_lix_value: 'is measured by the average sentence length in words and the proportion of long words, i.e. words of at least 7 characters:',
  corrector_complexity_infobox_noun_to_verb_bold: 'The noun-to-verb ratio',
  corrector_complexity_infobox_noun_to_verb_value: 'is used to determine the thematic density of the text. In written texts, on average, there is 1.9 times more nouns than verbs.',
  corrector_complexity_infobox_smog_bold: 'The SMOG index',
  corrector_complexity_infobox_smog_outro: 'about SMOG and Flesch-Kincaid grade level',
  corrector_complexity_infobox_smog_outro_extra: 'The latter source also offers insight into the value of the indexes at different levels of language proficiency.',
  corrector_complexity_infobox_smog_value: 'considers the frequency of polysyllabic words, which refers to words with at least 3 syllables per 30 sentences:',
  corrector_complexity_infobox_word_length_outro: 'To make editing and simplifying the text easier, the text box highlights long words, phrases, and nouns. Based on Jaan Mikk\'s example, sentences that go beyond 17 words are considered long. The reader\'s short-term memory is unlikely to store such sentences in their entirety (read more',
  corrector_complexity_level: 'Complexity level:',
  corrector_complexity_long_sentences: 'Long sentences',
  corrector_complexity_long_words: 'Long words',
  corrector_complexity_nouns: 'Nouns',
  corrector_complexity_statistics_long_words: 'Long words',
  corrector_complexity_statistics_nouns: 'Nouns',
  corrector_complexity_statistics_phrases: 'Sentences',
  corrector_complexity_statistics_polysyllabic_words: 'Polysyllabic words',
  corrector_complexity_statistics_syllables: 'Syllables',
  corrector_complexity_statistics_words: 'Words',
  corrector_download_docx_hover: 'Download the results as DOCX',
  corrector_errors_in_total: 'Corrections in total',
  corrector_flesch_kincaid_grade_level: 'Flesch-Kincaid grade level',
  corrector_grammar_errors_capitalization: 'Capitalization',
  corrector_grammar_errors_case: 'Case',
  corrector_grammar_errors_compounding: 'Compounding',
  corrector_grammar_errors_missing_punctuation: 'Missing punctuation',
  corrector_grammar_errors_missing_word: 'Missing word',
  corrector_grammar_errors_other_correction: 'Other correction',
  corrector_grammar_errors_punctuation_order: 'Punctuation order',
  corrector_grammar_errors_punctuation_replacement: 'Punctuation replacement',
  corrector_grammar_errors_spelling: 'Spelling',
  corrector_grammar_errors_tooltip: 'Correct the grammar and wording of sentences.',
  corrector_grammar_errors_unnecessary_punctuation: 'Unnecessary punctuation',
  corrector_grammar_errors_unnecessary_word: 'Unnecessary word',
  corrector_grammar_errors_verb_form: 'Verb form',
  corrector_grammar_errors_word_choice: 'Word choice',
  corrector_grammar_errors_word_order: 'Word order',
  corrector_grammar_errors_word_replacement: 'Word replacement',
  corrector_has_more_nouns: 'More nouns',
  corrector_has_more_verbs: 'More verbs',
  corrector_index_score_difficult: 'difficult',
  corrector_index_score_easy: 'easy',
  corrector_lix_index: 'LIX index',
  corrector_long_phrases_tooltip: 'Show sentences longer than 17 words. A lengthy sentence may be difficult to remember, causing the reader to miss its point. Try to break long sentences into shorter ones and avoid repetition.',
  corrector_long_words_tooltip: 'Show words longer than 6 letters. The text becomes more difficult to read if there is a significant amount of words that are longer than average. It\'s better to use Estonian words over foreign ones and verbs over nouns that end in \'-mine\' (e.g. Politsei teostas läbiotsimise -> Politsei otsis läbi).',
  corrector_noun_to_verb_ratio: 'Noun-to-verb ratio',
  corrector_nouns_tooltip: 'Show the nouns in the text. A high proportion of nouns indicates the thematic density of the text, which can make it difficult to understand. In contrast, the use of verbs is beneficial for comprehension.',
  corrector_proficiency_level: 'Proficiency level',
  corrector_proficiency_level_color_codes: 'Level color codes',
  corrector_proficiency_level_color_codes_A2: 'A2',
  corrector_proficiency_level_color_codes_B1: 'B1',
  corrector_proficiency_level_color_codes_B2: 'B2',
  corrector_proficiency_level_color_codes_C1: 'C1',
  corrector_proficiency_level_evaluation_grammar: 'Grammar',
  corrector_proficiency_level_evaluation_text_complexity: 'Text complexity',
  corrector_proficiency_level_evaluation_vocabulary: 'Vocabulary',
  corrector_proficiency_level_grammar: 'Grammar',
  corrector_proficiency_level_gray_box: 'See which language proficiency level (A2-C1) your text most likely corresponds to. In addition to the overall score, you can view separate scores for text complexity, grammar and vocabulary.',
  corrector_proficiency_level_infobox_intro: 'Text classification models that predict language proficiency levels are based on language features of creative writings (descriptions-narratives, discussions, personal and semi-formal letters) included in Estonian language proficiency exams. Thus, the machine-learning models here have been trained to distinguish between the four language proficiency levels that can be tested nationally (A2-C1). It is most likely that texts at level A1 will be classified at level A2, and texts at level C2 will be classified at level C1.',
  corrector_proficiency_level_infobox_list_bold_grammar: 'Grammar',
  corrector_proficiency_level_infobox_list_bold_overall: 'Overall complexity',
  corrector_proficiency_level_infobox_list_bold_overall_score: 'The overall score',
  corrector_proficiency_level_infobox_list_bold_vocabulary: 'Vocabulary',
  corrector_proficiency_level_infobox_list_grammar_value: 'proportions of parts of speech and grammatical forms, variety of word forms;',
  corrector_proficiency_level_infobox_list_header: 'The corrector evaluates the following text features:',
  corrector_proficiency_level_infobox_list_overall_score_value: 'takes all these aspects into consideration. The sub-scores can differ from one another and from the overall score.',
  corrector_proficiency_level_infobox_list_overall_value: 'length of text, words and sentences;',
  corrector_proficiency_level_infobox_list_vocabulary_value: 'lexical diversity (the number of unique words relative to the total number of words in the text), range (the proportion of less frequently occurring vocabulary) and noun abstractness.',
  corrector_proficiency_level_infobox_outro: 'In the case of a text written by a native Estonian speaker, the assessment provides an indication of the level of language skills required to write such a text. The appropriateness of a reading text can only be approximated because texts written and read at different levels do not have a one-to-one relationship.',
  corrector_proficiency_level_overall_score: 'Overall score',
  corrector_proficiency_level_short_text: 'Insert a longer text to get a proficiency level evaluation',
  corrector_proficiency_level_spelling: 'Spelling',
  corrector_proofreading: 'Proofreading',
  corrector_proofreading_gray_box: 'Check for potential errors in the text and enhance spelling, sentence structure, and wording. In addition, you can see the number of errors for each error type.',
  corrector_proofreading_infobox: 'The text can be corrected using two proofreaders created in cooperation between the University of Tartu and Tallinn University. A statistical spell checker detects spelling mistakes by analysing the context of the words. Machine translation-based grammar checker can detect sentence errors, such as punctuation and word order. It may suggest rewording even if there are no errors. Some errors can also be overlooked. Read more',
  corrector_smog_index: 'SMOG index',
  corrector_spelling_errors_tooltip: 'Correct the spelling of words.',
  corrector_test_version_info: 'The demo application has been developed in collaboration between the University of Tartu and Tallinn University as part of the procurement project “Automatic Corrector for Estonian as a Second Language for Learners and Teachers”. The goal is to create a language learning tool that not only corrects text but also classifies and explains the corrections. The project is funded by the Ministry of Education and Research and the European Union-supported program “Estonian Language Learning and Language Learning Development”, also known as “Keelesamm”.',
  corrector_test_version_text: 'This is a test version of the corrector and error explainer. Errors may occur in the application\'s operation. If you notice any issues or would like to provide feedback, please contact us.',
  corrector_test_version_thanks: 'Thank you for helping to develop the application!',
  corrector_vocabulary: 'Vocabulary',
  corrector_vocabulary_abstract_words: 'Abstract words',
  corrector_vocabulary_abstract_words_tooltip: 'Show words that indicate intangible phenomena. Words with common meanings can be interpreted in many ways while a more abstract text is harder to comprehend.',
  corrector_vocabulary_content_words: 'Content words',
  corrector_vocabulary_content_words_tooltip: 'Show content words, the higher percentage of which makes the text more concise. It’s more difficult and time-consuming to read a text more concise than average.',
  corrector_vocabulary_diverse_vocabulary: 'More diverse vocabulary',
  corrector_vocabulary_gray_box: 'Assess the complexity of the text\'s vocabulary. Like text complexity, vocabulary complexity can indicate the author\'s eloquence and text comprehensibility for the reader. If needed, you can vary the use of words or decrease the number of complex ones.',
  corrector_vocabulary_hdd_index: 'HDD index',
  corrector_vocabulary_indexes_root_type_token_ratio: 'Root type-token ratio',
  corrector_vocabulary_infobox_hdd_bold: 'The HDD index',
  corrector_vocabulary_infobox_hdd_value: 'is calculated by determining the probability of any text word occurring in a random 42-word segment of the text. The probabilities are calculated using the hypergeometric distribution function and added together. If the text is shorter than 42 words, the HDD value is not calculated.',
  corrector_vocabulary_infobox_intro: 'The corrector measures vocabulary diversity, range, abstractness, and density using the following metrics.',
  corrector_vocabulary_infobox_lexical_density_bold: 'Lexical density',
  corrector_vocabulary_infobox_lexical_density_link: 'Kristel Uiboaed\'s list of stop words is used.',
  corrector_vocabulary_infobox_lexical_density_value: 'is the proportion of content words in a text. To distinguish them from function words (e.g. pronouns, conjunctions, adpositions, verbal particles and auxiliary verbs),',
  corrector_vocabulary_infobox_mtld_bold: 'The MTLD index',
  corrector_vocabulary_infobox_mtld_value: 'measures the root type-token ratio in consecutive text sections. At first, the ratio is 1. At each word, it is recalculated until the value drops below the limit of 0.72. Then the value of the ratio is appointed 1 again and the cycle repeats itself. At the end of the text, the number of words is divided by the number of cycles. The same process is then repeated, moving from the back to the front of the text. In the end, the average of the two figures is calculated.',
  corrector_vocabulary_infobox_noun_abstractness_bold: 'Noun abstractness',
  corrector_vocabulary_infobox_noun_abstractness_value: 'is measured on a three-step scale: 1 – indicating tangible items and beings (e.g. auto); 2 – indicating tangible activities and phenomena (e.g. jooks); 3 – indicating intangible theoretical constructs (e.g. võrdsus). We’re using a',
  corrector_vocabulary_infobox_noun_abstractness_value_end: 'collected by Jaan Mikk et al (2003)(see table 1).',
  corrector_vocabulary_infobox_noun_abstractness_value_second: ' developed in Tartu University for finding the average noun abstractness. The estimates of the tool are based on',
  corrector_vocabulary_infobox_outro: 'The text box highlights word repetitions, words that are not in the list of the 5,000 most frequent words, abstract nouns, and content words. A repetition refers to the repetition of the same word in one or two consecutive sentences. Common conjunctions (e.g. ja, et) and the negative are not considered repeated words. Repetition of personal pronouns (mina, sina, tema) is only highlighted if the exact same word form occurs in the same sentence.',
  corrector_vocabulary_infobox_reference_links: 'Read about the indicators of vocabulary complexity',
  corrector_vocabulary_infobox_root_type_token_bold: 'The root type-token ratio',
  corrector_vocabulary_infobox_root_type_token_value: 'describes the amount of words with a unique base form in the text and is less dependent on the text length than the ratio before square root calculation:',
  corrector_vocabulary_infobox_vocabulary_range_bold: 'The vocabulary range',
  corrector_vocabulary_infobox_vocabulary_range_value: 'The vocabulary range indicates the percentage of relatively rare vocabulary in the text. We’ve regarded words that don’t belong in the list of the 5,000 most used words, rare. The presence of these words separates C1 level texts (with rare words averaging around 6-7% of the text) from lower language proficiency levels (where rare words make up about 3-4% of the text). This is based on the frequency list of base words in the Tasakaalus corpus that includes journalism, fiction and scientific literature (see also',
  corrector_vocabulary_less_content_words: 'Less content words',
  corrector_vocabulary_less_frequent_vocabulary: 'Less frequent vocabulary',
  corrector_vocabulary_lexical_density: 'Lexical density',
  corrector_vocabulary_low_frequency_words: 'Low-frequency words',
  corrector_vocabulary_low_frequency_words_tooltip: 'Show words that do not fall under the 5,000 most commonly used in Estonian. The text becomes more complex with the use of low frequency words, especially for beginners.',
  corrector_vocabulary_more_abstract_vocabulary: 'More abstract vocabulary',
  corrector_vocabulary_more_concrete_vocabulary: 'More concrete vocabulary',
  corrector_vocabulary_more_content_words: 'More content words',
  corrector_vocabulary_more_frequent_vocabulary: 'More frequent vocabulary',
  corrector_vocabulary_mtld_index: 'MTLD index',
  corrector_vocabulary_noun_abstractness: 'Noun abstractness',
  corrector_vocabulary_range: 'Vocabulary range',
  corrector_vocabulary_repetitive_vocabulary: 'Repetitive vocabulary',
  corrector_vocabulary_statistics_abstract_nouns: 'Abstract nouns',
  corrector_vocabulary_statistics_content_words: 'Content words',
  corrector_vocabulary_statistics_different_words: 'Different words',
  corrector_vocabulary_statistics_low_frequency_words: 'Low frequency words',
  corrector_vocabulary_statistics_the_words_considered: 'The words considered',
  corrector_vocabulary_word_repetitions: 'Word repetitions',
  corrector_vocabulary_word_repetitions_tooltip: 'Show words that repeat in the same sentence or two consecutive sentences. To avoid unnecessary word repetitions, look for synonyms in dictionaries, and use pronouns for references.',
  end: 'end',
  error_404_page_not_found: '404: page not found',
  error_file_upload_failed: 'File upload failed.',
  error_forbidden: 'You do not have permission to perform this action.',
  error_generic_server_error: 'Operation failed. Please try again later.',
  error_id_code_missing: 'Login failed. Save your personal code to your HarID account before logging in to ELLE and try again.',
  error_login_failed: 'Login failed. Please try again later.',
  error_mandatory_field: 'This field is mandatory.',
  error_no_matching_keywords: 'The keyword could not be found in the text. Change the analysis options and try again.',
  error_page_not_found: 'Page not found',
  error_query_no_subcorpus_picked: 'Choose at least one subcorpus.',
  error_too_many_requests: 'You have made too many requests in a short period. Please wait a moment and try again.',
  error_unauthorized: 'Please log in to continue.',
  error_unsupported_mimetype: 'One or more files you uploaded are not in the correct format. Please check each file and try again.',
  exit: 'EXIT',
  filter_by_syllable: 'Filter by syllable placement in the word',
  filter_by_word_form: 'Filter by word form',
  filter_by_word_type: 'Filter by word type',
  find_out_more: 'Find out more',
  footer_contact: 'Contact',
  footer_references: 'References',
  footer_references_elle_github: 'ELLE GitHub repository',
  footer_references_elle_license: 'ELLE license',
  footer_references_evkk: 'Estonian Interlanguage Corpus (EIC)',
  footer_references_evkk_license: 'EIC license',
  footer_tools: 'Tools',
  forward: 'NEXT',
  gram_anal_filename: 'gram_analysis',
  hero_main_text_highlighted: 'Estonian language learning and analysis environment',
  hero_main_text_not_highlighted: ' for learners, educators and linguists.',
  hero_tools_button: 'Check out our tools',
  homepage_box_clusters_content: 'allows you to find typical word sequences from the text.',
  homepage_box_clusters_title: 'Cluster Catcher',
  homepage_box_concordances_content: 'allows you to explore the usage of a chosen word.',
  homepage_box_concordances_title: 'Word in Context',
  homepage_box_corrector_content: 'allows you to evaluate your writing. You can see what the robot thinks about the spelling of your text and what language proficiency level it corresponds to.',
  homepage_box_corrector_title: 'Writing Evaluator',
  homepage_box_neighbouring_words_content: 'allows you to find words that often co-occur in the text.',
  homepage_box_neighbouring_words_title: 'Neighbouring Words',
  homepage_box_query_content: 'allows you to search for language material in EIC text collections based on author and text data. You can also submit your own text for research.',
  homepage_box_query_title: 'Text query',
  homepage_box_word_analysis_content: 'allows you to find syllables, base forms and grammatical forms from texts.',
  homepage_box_word_analysis_title: 'Word Analyser',
  homepage_box_wordlist_content: 'allows you to see which words occur most frequently in the text.',
  homepage_box_wordlist_title: 'Wordlist',
  homepage_services_title: 'What can you do in ELLE?',
  homepage_summary_text_1: 'Apply data-driven learning where the learner can derive or confirm language rules on their own based on authentic language material.',
  homepage_summary_text_2: 'Edit texts written in educational as well as work settings, e.g., correct written assignments and simplify official texts.',
  homepage_summary_text_3: 'Check student writings, choose and compile tutoring materials based on typical language use of learners at various proficiency levels.',
  homepage_summary_text_4: 'Analyse the content and language use of texts for research purposes, e.g., in language, culture and media studies.',
  homepage_summary_title: 'What does ELLE support?',
  homepage_title: 'ELLE - Estonian Language Learning, Teaching and Research Environment',
  homepage_title_explainer: 'ELLE offers text collections and text analysis tools for learning and teaching Estonian both as a second and native language, as well as for language research. The text query enables you to find materials from the Estonian Interlanguage Corpus (EIC) that mostly includes writings by learners of Estonian as a second language, but also examples of native language use and academic texts. Additionally, the tools allow you to analyse the vocabulary and grammar of self-selected texts, check their readability score and assess their language proficiency level.',
  input_field_tutorial_text: 'You can click on the words in this textbox. Clicking them will highlight the selected word.',
  lemmas_filename: 'baseforms',
  lemmas_header_wordforms: 'Word forms',
  links_academic_texts: 'Academic texts',
  links_language_courses: 'Language courses',
  links_language_learning_environments: 'Language learning environments',
  links_language_learning_games: 'Language learning games',
  links_language_learning_materials_and_exercises: 'Language learning materials and exercises',
  links_literature: 'Literature',
  links_media_texts: 'Media texts',
  links_radio_audio_podcasts: 'Radio, audio, podcasts',
  links_series_films_videos: 'Series, films, videos',
  middle: 'middle',
  navbar_logout: 'Log out',
  neighbouring_words_case_sensitive_hover: 'Search words beginning with uppercase or lowercase letters are not recognized by default, e.g. searching for "eesti" allows you to find the neighbouring words of both "eesti" and "Eesti". Tick the box if you only want the results beginning with uppercase or lowercase letters.',
  neighbouring_words_choose_statistic_measure: 'Choose a statistic measure',
  neighbouring_words_collocation: 'Neighbouring word',
  neighbouring_words_frequency_in_text: 'Frequency in text',
  neighbouring_words_keyword_lemmatization_warning: 'The keyword "{{initialKeywordResult}}" had no matches. Automatic lemmatization was used and neighbouring words for the word "{{lemmatizedKeywordResult}}" were found.',
  neighbouring_words_number_of_co_occurrences: 'Number of co-occurrences',
  neighbouring_words_percentage_in_text: 'Percentage in text',
  neighbouring_words_score: 'Score',
  neighbouring_words_search_for_neighbouring_words: 'Search for neighbouring words',
  neighbouring_words_search_within_preceding_and_following_words: 'within preceding and following words',
  neighbouring_words_statistic_measure_hover: 'We offer three statistic measures for finding neighbouring words, which give slightly different results. logDice is independent of the text length and is therefore best suited for analysing large text collections. For individual texts, the results obtained with logDice and T-score are quite similar. The MI-score is better at highlighting low-frequency word combinations, but it also identifies more empty words, which occur in close proximity of many words, as important neighbouring words.',
  neighbouring_words_statistic_measure_logdice: 'logDice',
  neighbouring_words_statistic_measure_mi_score: 'MI-score',
  neighbouring_words_statistic_measure_t_score: 'T-score',
  page_title_homepage: 'Homepage',
  pagination_go_to_page: 'Go to page:',
  pagination_page: 'Page',
  publish_your_text_author_data_other_languages_tooltip: 'Enter the languages, separated by commas, starting with the language you understand best',
  publish_your_text_content: 'Text',
  publish_your_text_content_helper_text: 'Upload your text or enter it into the text box',
  publish_your_text_exercise_description: 'Description of the exercise',
  publish_your_text_submit_button: 'Publish text',
  publish_your_text_terms_of_service_1: 'I grant permission to use my text and background data for academic and educational purposes by researchers and students, teachers and learners as well as software developers.',
  publish_your_text_terms_of_service_2: 'Personal data has been removed, and the author is not identified in the database. If a publicly accessible text is to be added to the corpus, the licensing terms of the publication must be followed. All copyrights are reserved.',
  publish_your_text_terms_of_service_infobox_1: 'By publishing the text, I agree to the ',
  publish_your_text_terms_of_service_infobox_2: 'ELLE terms of use',
  publish_your_text_terms_of_service_title: 'ELLE terms of use',
  publish_your_text_text_data_academic_category: 'Category',
  publish_your_text_text_data_academic_category_academic_studies: 'Academic studies',
  publish_your_text_text_data_academic_category_research: 'Research',
  publish_your_text_text_data_main_text_type: 'Main type',
  publish_your_text_text_data_main_text_type_academic: 'Academic',
  publish_your_text_text_data_main_text_type_non_academic: 'Non-academic',
  publish_your_text_text_data_number: 'Number',
  publish_your_text_text_data_pages: 'Pages',
  publish_your_text_text_data_publication: 'Publication',
  publish_your_text_text_data_sub_text_type: 'Sub-type',
  publish_your_text_text_data_supporting_material: 'Study or supporting material',
  publish_your_text_text_data_supporting_material_other: 'Other study or supporting material',
  publish_your_text_text_data_year: 'Year',
  publish_your_text_title: 'Title',
  query_author_data_age: 'Age',
  query_author_data_age_over_40: 'over 40',
  query_author_data_age_up_to_18: 'up to 18',
  query_author_data_country: 'Country of residence',
  query_author_data_country_other: 'Other country of residence',
  query_author_data_degree: 'Research degree',
  query_author_data_degree_bachelors: 'Bacherlor\'s degree',
  query_author_data_degree_doctoral: 'Doctoral degree',
  query_author_data_degree_masters: 'Master\'s degree',
  query_author_data_education: 'Level of education',
  query_author_data_education_elementary: 'elementary/basic education',
  query_author_data_education_higher: 'higher education',
  query_author_data_education_secondary: 'secondary education',
  query_author_data_education_vocational: '(secondary) vocational education',
  query_author_data_gender: 'Gender',
  query_author_data_gender_female: 'female',
  query_author_data_gender_male: 'male',
  query_author_data_level_of_study: 'Level of study',
  query_author_data_level_of_study_bachelors_studies: 'Bachelor\'s studies',
  query_author_data_level_of_study_doctoral_studies: 'Doctoral studies',
  query_author_data_level_of_study_masters_studies: 'Master\'s studies',
  query_author_data_nationality: 'Nationality',
  query_author_data_native_language: 'Native language',
  query_author_data_other_languages: 'Other language of study or work',
  query_author_data_other_languages_plural: 'Other languages of study or work',
  query_change_chosen_corpuses: 'Change search',
  query_choose_texts: 'Choose texts',
  query_common_country_de: 'Germany',
  query_common_country_en: 'England',
  query_common_country_et: 'Estonia',
  query_common_country_fi: 'Finland',
  query_common_country_hu: 'Hungary',
  query_common_country_lt: 'Lithuania',
  query_common_country_lv: 'Latvia',
  query_common_country_other: 'Other',
  query_common_country_ru: 'Russia',
  query_common_country_se: 'Sweden',
  query_common_language_by: 'Belarusian',
  query_common_language_cn: 'Chinese',
  query_common_language_de: 'German',
  query_common_language_en: 'English',
  query_common_language_et: 'Estonian',
  query_common_language_et_ru: 'Estonian, Russian',
  query_common_language_fi: 'Finnish',
  query_common_language_fr: 'French',
  query_common_language_hu: 'Hungarian',
  query_common_language_ji: 'Yiddish',
  query_common_language_jp: 'Japanese',
  query_common_language_lt: 'Lithuanian',
  query_common_language_lv: 'Latvian',
  query_common_language_pl: 'Polish',
  query_common_language_ru: 'Russian',
  query_common_language_se: 'Swedish',
  query_common_language_ua: 'Ukrainian',
  query_common_nationality_af: 'Afghan',
  query_common_nationality_az: 'Azerbaijani',
  query_common_nationality_bg: 'Bulgarian',
  query_common_nationality_br: 'Brazilian',
  query_common_nationality_by: 'Belarusian',
  query_common_nationality_ca: 'Canadian',
  query_common_nationality_cn: 'Chinese',
  query_common_nationality_de: 'German',
  query_common_nationality_eg: 'Egyptian',
  query_common_nationality_es: 'Spanish',
  query_common_nationality_et: 'Estonian',
  query_common_nationality_fi: 'Finnish',
  query_common_nationality_fr: 'French',
  query_common_nationality_gb: 'British',
  query_common_nationality_gr: 'Greek',
  query_common_nationality_hu: 'Hungarian',
  query_common_nationality_ie: 'Irish',
  query_common_nationality_in: 'Indian',
  query_common_nationality_it: 'Italian',
  query_common_nationality_kz: 'Kazakh',
  query_common_nationality_lt: 'Lithuanian',
  query_common_nationality_lv: 'Latvian',
  query_common_nationality_md: 'Moldovan',
  query_common_nationality_mk: 'Macedonian',
  query_common_nationality_nl: 'Dutch',
  query_common_nationality_ph: 'Filipino',
  query_common_nationality_pl: 'Polish',
  query_common_nationality_ro: 'Romanian',
  query_common_nationality_ru: 'Russian',
  query_common_nationality_sy: 'Syrian',
  query_common_nationality_tr: 'Turkish',
  query_common_nationality_ua: 'Ukrainian',
  query_common_nationality_unassigned: 'Unassigned',
  query_common_nationality_us: 'U.S.',
  query_common_nationality_ve: 'Venezuelan',
  query_download_basictext: 'Text only',
  query_download_form: 'Format',
  query_download_license: 'License:',
  query_download_single_file: 'Single file',
  query_download_stanza: 'Syntactic annotation – CoNLL-U',
  query_download_tei: 'TEI annotation',
  query_download_vislcg3: 'Syntactic annotation – VISLCG3',
  query_download_zip: 'ZIP file',
  query_filters: 'Search filters',
  query_own_texts: 'Your own texts',
  query_results_accordion_title: 'Search results',
  query_results_found_texts: 'Texts found:',
  query_results_no_texts_found: 'No texts were found.',
  query_results_preview_metadata_modal_title: 'Text metadata',
  query_results_save_texts_for_analysis: 'Save texts for analysis',
  query_results_saved_for_analysis: 'Texts saved for analysis:',
  query_results_saved_for_analysis_corpus: 'corpus text',
  query_results_saved_for_analysis_corpus_plural: 'corpus texts',
  query_results_saved_for_analysis_own_texts: 'own texts',
  query_results_select_all: 'Select all',
  query_results_unselect_all: 'Unselect all',
  query_subcoprus_L2_olympiade: 'Estonian L2 olympiade',
  query_subcoprus_L2_olympiade_hover: 'Includes creative writings from the olympiade of Estonian as a second language.',
  query_subcorpus: 'Subcorpus',
  query_subcorpus_L1_estonian: 'L1 Estonian',
  query_subcorpus_L1_estonian_hover: 'Reference corpus, includes mainly native speakers\' opinion pieces from newspapers.',
  query_subcorpus_L1_russian: 'L1 Russian',
  query_subcorpus_L1_russian_hover: 'Includes elementary school and high school Russian-speaking students\' texts of a creative nature.',
  query_subcorpus_L2_estonian: 'L2 Estonian',
  query_subcorpus_L2_estonian_hover: 'Includes Estonian learner writings produced outside examination situations. A number of texts have received a proficiency assessment (level A1-C2) by at least two recognized specialists.',
  query_subcorpus_L2_proficiency_examinations: 'Estonian L2 proficiency exams',
  query_subcorpus_L2_proficiency_examinations_hover: 'Includes writings from Estonian language proficiency exams.',
  query_subcorpus_L3_russian: 'L3 Russian',
  query_subcorpus_L3_russian_hover: 'Includes texts written by native Estonian students learning Russian as their third language at school.',
  query_subcorpus_academic_estonian: 'Academic Estonian',
  query_subcorpus_academic_estonian_hover: 'Includes examples of academic language use (reports, term papers, theses etc.) by students speaking Estonian as a first or second language.',
  query_subcorpus_all: 'all',
  query_text_data_char_over_5000: 'over 5000',
  query_text_data_char_up_to_500: 'up to 500',
  query_text_data_characters: 'Character count',
  query_text_data_language: 'Language',
  query_text_data_level: 'Proficiency level',
  query_text_data_material_selected_plural: '{{amount}} materials selected',
  query_text_data_over: 'over',
  query_text_data_sentences_over_100: 'over 100',
  query_text_data_sentences_up_to_10: 'up to 10',
  query_text_data_type: 'Type',
  query_text_data_type_L1_examination: 'L1 examination',
  query_text_data_type_L1_exercise: 'L1 exercise',
  query_text_data_type_L1_opinion_piece: 'L1 opinion piece',
  query_text_data_type_L1_russian_creative_writing: 'L1 Russian creative writing',
  query_text_data_type_L1_russian_examination: 'L1 Russian examination',
  query_text_data_type_L2_creative_writing: 'L2 creative writing',
  query_text_data_type_L2_examination: 'L2 examination',
  query_text_data_type_L2_exercise: 'L2 exercise',
  query_text_data_type_L2_exercise_ad: 'Advertisement',
  query_text_data_type_L2_exercise_ad_full: 'L2 exercise: advertisement',
  query_text_data_type_L2_exercise_announcement: 'Announcement',
  query_text_data_type_L2_exercise_announcement_full: 'L2 exercise: announcement',
  query_text_data_type_L2_exercise_answering_questions: 'Answering questions',
  query_text_data_type_L2_exercise_answering_questions_full: 'L2 exercise: answering questions',
  query_text_data_type_L2_exercise_contract: 'Contract',
  query_text_data_type_L2_exercise_contract_full: 'L2 exercise: contract',
  query_text_data_type_L2_exercise_description: 'Description',
  query_text_data_type_L2_exercise_description_full: 'L2 exercise: description',
  query_text_data_type_L2_exercise_dialogue: 'Dialogue',
  query_text_data_type_L2_exercise_dialogue_full: 'L2 exercise: dialogue',
  query_text_data_type_L2_exercise_dictation: 'Dictation',
  query_text_data_type_L2_exercise_dictation_full: 'L2 exercise: dictation',
  query_text_data_type_L2_exercise_fill_in_the_blanks: 'Fill-in-the-blanks',
  query_text_data_type_L2_exercise_fill_in_the_blanks_full: 'L2 exercise: fill-in-the-blanks',
  query_text_data_type_L2_exercise_guide: 'Guide',
  query_text_data_type_L2_exercise_guide_full: 'L2 exercise: guide',
  query_text_data_type_L2_exercise_instruction: 'Instruction',
  query_text_data_type_L2_exercise_instruction_full: 'L2 exercise: instruction',
  query_text_data_type_L2_exercise_menu: 'Menu',
  query_text_data_type_L2_exercise_menu_full: 'L2 exercise: menu',
  query_text_data_type_L2_exercise_recipe: 'Recipe',
  query_text_data_type_L2_exercise_recipe_full: 'L2 exercise: recipe',
  query_text_data_type_L2_exercise_retelling: 'Retelling',
  query_text_data_type_L2_exercise_retelling_full: 'L2 exercise: retelling',
  query_text_data_type_L2_exercise_sentence_construction: 'Sentence construction',
  query_text_data_type_L2_exercise_sentence_construction_full: 'L2 exercise: sentence construction',
  query_text_data_type_L2_exercise_speech: 'Speech',
  query_text_data_type_L2_exercise_speech_full: 'L2 exercise: speech',
  query_text_data_type_L2_letter: 'L2 letter',
  query_text_data_type_L2_letter_informal: 'Informal',
  query_text_data_type_L2_letter_informal_full: 'L2 letter: informal',
  query_text_data_type_L2_letter_semi_formal: '(Semi-)formal',
  query_text_data_type_L2_letter_semi_formal_full: 'L2 letter: (semi-)formal',
  query_text_data_type_L2_test: 'L2 test',
  query_text_data_type_L2_translation: 'L2 translation',
  query_text_data_type_L3_russian_creative_writing: 'L3 Russian creative writing',
  query_text_data_type_L3_russian_examination: 'L3 Russian examination',
  query_text_data_type_academic_research: 'Academic research',
  query_text_data_type_academic_research_bachelors_thesis: 'Bachelor\'s thesis',
  query_text_data_type_academic_research_bachelors_thesis_full: 'Academic research: bachelor\'s thesis',
  query_text_data_type_academic_research_doctoral_thesis: 'Doctoral thesis',
  query_text_data_type_academic_research_doctoral_thesis_full: 'Academic research: doctoral thesis',
  query_text_data_type_academic_research_masters_thesis: 'Master\'s thesis',
  query_text_data_type_academic_research_masters_thesis_full: 'Academic research: master\'s thesis',
  query_text_data_type_academic_research_presentation: 'Presentation',
  query_text_data_type_academic_research_presentation_full: 'Academic research: presentation',
  query_text_data_type_academic_research_research_article: 'Article',
  query_text_data_type_academic_research_research_article_full: 'Academic research: article',
  query_text_data_type_academic_research_summary: 'Summary',
  query_text_data_type_academic_research_summary_full: 'Academic research: summary',
  query_text_data_type_academic_research_thesis: 'Thesis',
  query_text_data_type_academic_research_thesis_full: 'Academic research: thesis',
  query_text_data_type_academic_studies: 'Academic studies',
  query_text_data_type_academic_studies_analysis: 'Analysis',
  query_text_data_type_academic_studies_analysis_full: 'Academic studies: analysis',
  query_text_data_type_academic_studies_course_paper: 'Course paper',
  query_text_data_type_academic_studies_course_paper_full: 'Academic studies: course paper',
  query_text_data_type_academic_studies_essay: 'Essay',
  query_text_data_type_academic_studies_essay_full: 'Academic studies: essay',
  query_text_data_type_academic_studies_overview: 'Overview',
  query_text_data_type_academic_studies_overview_full: 'Academic studies: overview',
  query_text_data_type_academic_studies_report: 'Report',
  query_text_data_type_academic_studies_report_full: 'Academic studies: report',
  query_text_data_type_academic_studies_review: 'Review',
  query_text_data_type_academic_studies_review_full: 'Academic studies: review',
  query_text_data_type_academic_studies_seminar_paper: 'Seminar paper',
  query_text_data_type_academic_studies_seminar_paper_full: 'Academic studies: seminar paper',
  query_text_data_type_selected_plural: '{{amount}} types selected',
  query_text_data_up_to: 'up to',
  query_text_data_used_study_or_supporting_materials: 'Used study or supporting materials',
  query_text_data_used_study_or_supporting_materials_automated_correction: 'automated correction',
  query_text_data_used_study_or_supporting_materials_monolingual_dictionary: 'monolingual dictionary (incl. online dictionaries)',
  query_text_data_used_study_or_supporting_materials_no: 'no',
  query_text_data_used_study_or_supporting_materials_professional_handbook: 'professional handbook or manual',
  query_text_data_used_study_or_supporting_materials_term_glossary_or_base: 'term glossary or base',
  query_text_data_used_study_or_supporting_materials_translation_dictionary_or_machine_translation: 'translation dictionary or machine translation',
  query_text_data_used_study_or_supporting_materials_yes: 'yes',
  query_text_data_used_supporting_materials: 'Use of study materials',
  query_text_data_used_supporting_materials_no: 'spontaneous',
  query_text_data_used_supporting_materials_yes: 'prepared',
  query_text_data_words_over_800: 'over 800',
  query_text_data_words_up_to_100: 'up to 100',
  query_text_data_year_of_publication: 'Year of publication',
  restore_data_button: 'Restore data',
  send_request_button: 'Send request',
  server_offline_page_error: 'ELLE services are unavailable at the moment. Please try again later.',
  session_expiration_modal_content_1: 'Your session will expire in less than {{minutesLeft}} {{unit}}.',
  session_expiration_modal_content_2: 'Would you like to extend your session?',
  session_expiration_modal_renew_no: 'No',
  session_expiration_modal_renew_yes: 'Yes',
  session_expiration_modal_title: 'Session is about to expire',
  session_expiration_modal_unit_plural: 'minutes',
  session_expiration_modal_unit_singular: 'minute',
  start_button: 'Start',
  success_generic: 'Operation succeeded.',
  success_logout: 'You have been logged out successfully.',
  success_logout_forced: 'Your session has expired and you have been automatically logged out.',
  success_session_renew: 'Session has been renewed successfully.',
  syllables_beginning: 'beginning',
  syllables_end: 'end',
  syllables_filename: 'syllables',
  syllables_header_location: 'Position in word',
  syllables_header_syllable: 'Syllable',
  syllables_middle: 'middle',
  syllables_table_beginning: 'Position (beginning)',
  syllables_table_end: 'Position (middle)',
  syllables_table_middle: 'Position (end)',
  tab_gram_anal: 'Grammatical analysis',
  table_click_tutorial_text: 'Clicking on a value in the table will highlight all the words connected with the value in the textbox above.',
  table_tab_tutorial_text: 'Here you can select, what info you want to have displayed on the tables below.',
  table_tutorial_text: 'The tables display information about the words in the textbox above.',
  text_analysis: 'Text analysis',
  textupload_primary_modal_save: 'Save texts for analysis',
  textupload_primary_modal_title: 'Insert or upload your own texts',
  textupload_secondary_modal_choose_files: 'Choose files',
  textupload_secondary_modal_chosen_files: 'Chosen files:',
  textupload_secondary_modal_title: 'Choose text',
  textupload_secondary_modal_tooltip: 'Upload your own texts',
  textupload_secondary_modal_upload: 'Upload files',
  tools_accordion_analysis: 'Analysis tools',
  tools_accordion_analysis_infobox: 'To use the tools in gray, first select or upload text(s). It\'s not possible to direct texts into other tools: enter these in the text box or upload them.',
  tools_accordion_clusters_explainer: 'Clusters or n-grams help you find typical word sequences in the text',
  tools_accordion_neighbouring_words_explainer: 'Find words that are often used together in the text',
  tools_accordion_word_analysis_explainer: 'Find syllables, lexical and grammatical forms of words',
  tools_accordion_word_in_context_explainer: 'View a word\'s surroundings for a better overview of how it is used',
  tools_accordion_wordlist_explainer: 'View text words by frequency or in alphabetical order',
  tools_infobox: 'Search for texts in our EIC text collection and analyse them with our tools or upload your own text(s) for analysis.',
  tools_title: 'ELLE tools',
  tools_video_text: 'Watch introductory video',
  tools_warning_text: 'To use this tool, please choose a text or texts to analyse from the menu on the left.',
  try_again: 'Try again',
  word_analyser_text_too_long_infobox: 'Word-by-word text analysis is possible for texts up to 1,000 words.',
  word_analyser_word_with_foreign_characters_warning: 'The word contains foreign characters.',
  wordcontext_filename: 'wordcontext',
  wordinfo_tutorial_text: 'Clicking on a word will display the grammatical info of the word.',
  wordinfo_word: 'Word',
  wordlist_exclude_stopwords: 'Exclude stop words',
  wordlist_filename: 'wordlist',
  wordlist_lemma_column: 'Base form',
  wordlist_retain_uppercase_letters: 'retain uppercase letters',
  wordlist_retain_uppercase_letters_hover: 'The words will be converted to lowercase by default, for example "kool" and "Kool" will be considered the same word. Tick the box if you want uppercase and lowercase words to be counted separately (e.g. "Eesti" and "eesti").',
  wordlist_search_base_forms: 'base forms',
  wordlist_search_word_forms: 'word forms',
  wordlist_set_minimum_word_frequency: 'Set minimum word frequency',
  wordlist_set_minimum_word_frequency_hover: 'If, for example, you want to exclude words that occur only once in a text, set the lower frequency limit to 2. When analysing large text collections, words that occur less than 5 times are often excluded.',
  wordlist_stopwords_from_the_default_list: 'from the default list',
  wordlist_stopwords_textbox: 'Enter your stop words here (e.g. koer, kodu)',
  wordlist_stopwords_textbox_hover_1: 'The list of Estonian stop words was compiled by Kristel Uiboaed. It includes conjunctions, pronouns, light verbs and prepositions. The list is available in the data repository of Tartu University (see ',
  wordlist_stopwords_textbox_hover_2: 'here',
  wordlist_stopwords_textbox_hover_3: ').',
  wordlist_word_column: 'Word form',
  wordlist_wordcloud: 'Word cloud',
  wordlist_wordcloud_filename: 'wordcloud',
  wordlist_wordcloud_loading: 'Loading word cloud...'
};
