export const COMPLEXITY_MARKING_LONG_WORD_LIMIT = 6;
export const COMPLEXITY_MARKING_MINIMUM_WORDS_IN_LONG_SENTENCE = 15;
export const LONG_SENTENCE = 'longsentence';
export const LONG_WORD = 'longword';
export const NOUNS = 'nouns';
export const WORD_REPETITION = 'wordrepetition';
export const UNCOMMON_WORDS = 'uncommonwords';
export const ABSTRACT_WORDS = 'abstractwords';
export const CONTENT_WORDS = 'contentwords';
export const SPELLCHECKER = 'spellchecker';
export const GRAMMARCHECKER = 'grammarchecker';
export const GRAMMARCHECKER_TEST = 'grammarcheckerTest';
export const NOUN = 'NOUN';
export const PROPN = 'PROPN';
export const VERB = 'VERB';
export const NUM = 'NUM';
export const ARROW_KEYS = ['ArrowUp', 'ArrowDown', 'ArrowLeft', 'ArrowRight'];
export const CORRECTION = 'corrector_proofreading';
export const TEXTLEVEL = 'corrector_proficiency_level';
export const TEXTSPAN = 'text-span';
export const ACCEPT_ERROR = 'acceptError';
export const DECLINE_ERROR = 'declineError';

